<template>
	<S2SForm title="USSD2 MENU">
		<div v-if="ussdMenu">
			<v-data-table :headers="ussdHeader" :items="ussdMenu" class="elevation-1">
				<template v-slot:item.active="{ item }">
					<v-checkbox
						class="mt-0"
						:input-value="item.active"
						color="primary"
						hide-details
						@change="onUpdate($event, item)"
						@click.once.stop
					></v-checkbox>
				</template>
			</v-data-table>
		</div>
	</S2SForm>
</template>

<script>
export default {
	name: "USSDMenu",

	computed: {
		networks: function() {
			return this.$store.state.airtime.networks;
		},
		ussdMenu: function() {
			return this.$store.state.airtime.ussdMenu;
		}
	},

	data: function() {
		return {
			ussdHeader: [
				{
					text: "ID",
					value: "id"
				},
				{
					text: "Network",
					value: "network"
				},
				{
					text: "Value",
					value: "value"
				},
				{
					text: "Voucher Network",
					value: "voucherNetwork"
				},
				{
					text: "Active",
					value: "active"
				}
			]
		};
	},

	mounted: function() {
		this.$store.dispatch("airtime/fetchAirtimeNetworks");
		this.$store.dispatch("airtime/fetchUssdMenu");
	},

	methods: {
		onUpdate(active, item) {
			this.$store.dispatch("airtime/updateUssdMenu", { menuId: item.id, active: active });
		}
	}
};
</script>
